<template>
  <div class="new-konwledge-container">
    <el-form
      :ref="(el) => { formRef = el }"
      label-position="top"
      :model="formData"
      :rules="formRules"
      @submit.native.prevent=""
      @keypress.enter.prevent="handleSubmit"
    >
      <el-tabs tab-position="left" style="height: 600px">
        <el-tab-pane label="基本设置">
          <div class="label-item">基本设置</div>
          <el-form-item label="封面" prop="cover">
            <!-- <CoverSelect v-model="formData.cover" /> -->
            <FileUpload :fileTypeList="['png', 'jpg', 'jpeg']" :files="formData.cover" @extra-files="(res) => { formData.cover = res }" />
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入名称..."></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="type_id">
            <ClassificationSelect v-model="formData.type_id" @relist="relist"></ClassificationSelect>
          </el-form-item>
          <el-form-item label="描述" prop="desc">
            <el-input v-model="formData.desc" resize="none" type="textarea" :rows="4" placeholder="请输入描述"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="权限管理">
          <div class="label-item">权限管理</div>
          <el-form-item label="是否公开" prop="is_show">
            <el-switch
              v-model="formData.is_show"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
              :active-value="1"
              :inactive-value="0"
              active-text="公开"
              inactive-text="私有"
            />
          </el-form-item>
          <el-form-item label="管理员" prop="edit">
            <UserChose v-model="formData.edit"/>
          </el-form-item>
          <el-form-item label="可查看人员" prop="show">
            <UserChose v-model="formData.show"/>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class="action-row">
      <el-button type="primary" :loading="btnLoading" @click.stop="handleSubmit">保存</el-button>
    </div>
  </div>
</template>
<script>
import UserChose from '@/manage-views/views/knowledgeBase/components/userChose/index.vue'
import { defineComponent, reactive, watch, ref } from 'vue'
import { Tabs, TabPane } from 'element-ui'
import CoverSelect from '../components/CoverSelect.vue'
import ClassificationSelect from '../components/ClassificationSelect'
import FileUpload from '@/manage-views/components/FileUpload.vue'
export default defineComponent({
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    CoverSelect,
    UserChose,
    ClassificationSelect,
    FileUpload
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    btnLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const formRef = ref(null)
    const formData = reactive({
      cover: [],
      name: '',
      type_id: [],
      desc: '',
      edit: [],
      show: [],
      is_show: 1
    })
    const formRules = reactive({
      cover: [{ required: true, message: '不能为空', type: 'array', trigger: 'blur' }],
      name: [{ required: true, message: '不能为空', trigger: 'blur' }],
      type_id: [{ required: true, message: '不能为空', type: 'array', trigger: 'blur' }],
    })
    watch(() => props.data, (n) => {
      if(n) {
        for (const key in n) {
          if (Object.hasOwnProperty.call(n, key)) {
            if(Array.isArray(n[key])) {
              formData[key] = n[key].slice()
            } else {
              formData[key] = n[key]
            }
          }
        }
      }
    }, { immediate: true})
    const handleSubmit = () => {
      formRef.value.validate((valid) => {
        if(!valid) return
        ctx.emit('submit', formData)
      })
    }
    const relist = () =>{
      ctx.emit('relist')
    }
    return {
      formData,
      formRules,
      handleSubmit,
      formRef,
      relist
    }
  },
})
</script>
<style lang="less" scoped>
  .new-konwledge-container{
    text-align: left;
    width: 100%;
    .label-item{
      padding: 5px 0;
      &::before{
        content: '';
        border-left: 4px solid #409eff;
        margin-right: 10px;
      }
    }
    .action-row{
      padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
      height: 52px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
</style>
