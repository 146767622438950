<template>
  <div class="cover-select-container">
    <div class="item" :style="`background: ${value}`">
      <!-- 此处选用svg主要是为了更好的切换颜色 -->
      <svg t="1661140683420" class="action-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30027" width="24" height="24"><path d="M832 96a64 64 0 0 1 64 64v744.32a32 32 0 0 1-48.128 27.584L512 736l-335.872 195.904A32 32 0 0 1 128 904.32V160a64 64 0 0 1 64-64h640z m0 64H192v688.576l303.872-177.28a32 32 0 0 1 32.256 0L832 848.64V160zM672 320v64h-320V320h320z" fill="#fff" p-id="30028"></path></svg>
    </div>
    <div class="item" v-for="(item, index) in coverList" :key="index" @click.stop="handleItemClick(item)" :style="`background: ${item}`">
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const coverList = ref([
      'linear-gradient(-45deg, #409eff, blueviolet, pink);',
      'linear-gradient(-45deg, blueviolet, #409eff, pink);',
      'linear-gradient(-45deg, blueviolet, pink, #409eff);',
      'linear-gradient(-45deg, blueviolet, #409eff, pink);',
      'linear-gradient(-45deg, blueviolet, #409eff, orangered);',
      'linear-gradient(-45deg, #409eff, orangered, pink);'
    ])
    watch(() => props.value, (n) => {
      if(!n) {
        ctx.emit('input', 'linear-gradient(-45deg, #409eff, blueviolet, pink);')
      }
    }, { immediate: 'true' })

    const handleItemClick = (item) => {
      ctx.emit('input', item)
    }
    return {
      coverList,
      handleItemClick
    }
  },
})
</script>
<style lang="less" scoped>
  .cover-select-container{
    width: 100%;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 80px);
    .item{
      border-radius: 3px;
      position: relative;
      .action-icon{
        position: absolute;
        top: 10px;
        left: 10px;
      }
      &:first-child{
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 3;
        grid-row-end: 3;
      }
    }
  }
</style>
