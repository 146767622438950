<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-01-12 15:43:08
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-02-06 10:01:59
 * @FilePath: \dataview-next\src\manage-views\components\share\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="share-dialog">
    <el-form :model="form1" v-if="showFormType===1">
      <el-form-item label="分享形式" prop="resource">
        <el-radio-group v-model="form1.resource">
          <el-radio label="加密"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="有效期:" prop="indate">
        <el-select v-model="form1.indate" placeholder="请选择有效期"  style="margin-left:12px;width: 162px;">
          <el-option label="7天" value="1"></el-option>
          <el-option label="30天" value="2"></el-option>
          <el-option label="永久有效" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-btn">
        <el-button type="primary" @click="creatDate">创建链接</el-button>
      </el-form-item>
    </el-form>
    <el-form :model="form2" v-if="showFormType===2">
      <el-form-item label="分享链接：" prop="resource">
        <el-input v-model="form2.url" disabled style="width:300px">

        </el-input>
      </el-form-item>
      <el-form-item label="提取密码：" prop="resource">
        <el-input v-model="form2.pwd" style="width:162px" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="有效期:" prop="time_category">
        <el-select v-model="form2.time_category" style="margin-left:24px;width: 162px;" @change="editDate">
          <el-option label="7天" :value="1"></el-option>
          <el-option label="30天" :value="2"></el-option>
          <el-option label="永久有效" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-btn">
        <el-button type="primary" @click="close">取消</el-button>
        <el-button type="primary" @click="copy">复制链接</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { repositoryShare } from '@/saas-apis/konwledgeBase.js';
import ClipboardJS from 'clipboard'
export default {
  components: {},
  props: {
    infoType:{
      type:Number,
      default:1,
    },
    shareId:{
      type:Number,
      default:NaN,
    },
    infoUrl:{
      type:Object,
      default:() => {}
    },
    file_id:{
      type:String,
      default:''
    },
    shareType:{
      type:Number,
      default:1,
    },
  },
  data() {
    return {
      form1:{
        resource:'加密',
        indate:'1'
      },
      form2:{
      },
      showFormType:0,
    };
  },
  watch: {
    infoType:{
      handler(val){
        this.showFormType = val;
      },
      deep: true,
      immediate: true
    },
    infoUrl: {
      handler(val){
        if(val?.url){
          this.form2 = JSON.parse(JSON.stringify(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  methods: {
    creatDate(){
      let params = {
        repository_id:this.shareId,
        time_category:this.form1.indate,
        share_type:this.shareType,
      }
      if(this.shareType ===2 && this.file_id.length){
        params.file_id =this.file_id
      }
      repositoryShare(params).then((res)=>{
        if(res.data.code===200){
          this.form2 = {
            url:`https://www.bimcc.com/share?${res.data.data.uuid}`,
            pwd:res.data.data.code,
            time_category : res.data.data.time_category
          }
          this.showFormType = 2
        }
      })
    },
    copy(e){
      const clipboard = new ClipboardJS(e.target, { text: () => 
        `链接:${this.form2.url}
提取码:${this.form2.pwd}`})
        clipboard.text()
        clipboard.on('success', () => {
          this.$message.success('复制成功')
          this.$nextTick(() => {
            clipboard.destroy()
          })
        })
        clipboard.on('error', () => {
          this.$message.error('该浏览器不支持自动复制')
          this.$nextTick(() => {
            clipboard.destroy()
          })
        })
        clipboard.onClick(e)
    },
    editDate(){
      let params = {
        repository_id:this.shareId,
        time_category:this.form2.time_category,
        share_type:this.shareType,
      }
      if(this.shareType === 2 && this.file_id.length){
        params.file_id =this.file_id
      }
      repositoryShare(params).then((res)=>{
        if(res.data.code===200){
          this.$message.success('修改成功')
        }
      })
    },
    close(){
      this.$emit('close')
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
  :deep(.el-input){
    margin-left: 15px;
  }
  :deep(.el-form-item__label){
    color: #25282e !important;
    line-height: 32px !important;
    padding: 0 !important;
  }
  :deep(.el-radio-group){
    
    margin-left: 19px;
    .el-radio{
      color: #25282e !important;
    }
    .el-radio__input.is-checked+.el-radio__label{
      color: #25282e !important;
    }
    .el-radio__input.is-checked .el-radio__inner{
      border-color: #0076ff;
    background: #0076ff;
    }
  }
  :deep(.el-button--primary){
    background: #0076ff;
  }
</style>
<style lang="less" scoped>

.share-dialog{
  
  .form-btn{
    color: #ffffff;
    display: flex;
    justify-content:flex-end;
  }
}
</style>